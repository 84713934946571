package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun ServicesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("Services.md", mapOf())) {
        uk.co.comsci.web.components.layouts.MarkdownLayout("Custom Software Services provided by Comsci Ltd", "Bespoke software development to create custom applications for desktop, embedded, mobile apps and workstations") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("custom-software-app-development") }) {
                org.jetbrains.compose.web.dom.Text("Custom Software App Development")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Bespoke software development to meet any requirements:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Embedded microcontroller firmware development")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Embedded custom Linux solutions including over the air updates.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Single board computer applications and integration with sensors.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Android App development using Google best practices in Jetpack Compose and Kotlin.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Multiplatform Desktop PC package creation in kotlin, java, C++ as appropriate")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Unix workstation or multi-node multiprocessor High Performance Computing (HPC) solutions")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("We provide:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Complete turnkey solutions")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Specialist software development. To complement an existing project or device.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Testing services. White box, black box, code coverage etc.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("3rd party independent code reviews, validation and verification.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Contract Programming for hire. To supplement an existing project.")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Please get in ")
                com.varabyte.kobweb.silk.components.navigation.Link("/Contact") {
                    org.jetbrains.compose.web.dom.Text("Contact")
                }
                org.jetbrains.compose.web.dom.Text(" to start a discussion.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Scientific and numerical background is well suited to writing technically challenging industrial control software, signal processing pipelines and data modelling applications.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Network of contacts available to complete larger projects or draw in engineering and hardware expertise to supplement skills.")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/technical") {
                    org.jetbrains.compose.web.dom.Text("Technical Details")
                }
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/") {
                    org.jetbrains.compose.web.dom.Text("Back")
                }
            }
        }
    }
}
