package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun HowMuchPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("HowMuch.md", mapOf())) {
        uk.co.comsci.web.components.layouts.MarkdownLayout("How much does it cost to create a Custom App?", "A rough guidleline thought process for estimating how much it would cost to develop a bespoke software application") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("what-does-it-cost-to-develop-an-app") }) {
                org.jetbrains.compose.web.dom.Text("What does it cost to develop an App")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("How long is a piece of string?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It all depends.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Enter \"")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("what does it cost to develop an app")
                }
                org.jetbrains.compose.web.dom.Text("\" in a search engine and you will get numerous hits giving ways to estimate but the end result will usually be somewhere between £5000 to £300,000")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Although this example is based around an Android App, a similar thought process can be applied to any other embedded, desktop, or cloud based project.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you are after an App with one simple screen and limited functionality then it might be only a few days work or around £1000.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For example, I built a simple App to allow me to coordinate a local fireworks display:")
                com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                org.jetbrains.compose.web.dom.Text(" A single screen displaying a table of fireworks in firing order, each with a description, a fire button/ burning display, an expected duration, a countdown timer and a rating bar. As each firework burns, the timer will count down and then indicate when to light the next firework in sequence:")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/lightemup2.jpg\" class=\"narrow-port\" alt=\"Fireworks App image\"")
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This example represents the baseline starting point:")
                com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                org.jetbrains.compose.web.dom.Text(" Only 1 screen, no login, no complicated data model, no user settings, no App store presence, no data storage requirements, no database, no fancy branding or theming, no remote network connectivity, no manual, no source code to deliver, no promotional material etc. Each of these extra requirements could add a week or more to the task. However, the app does its job and a similar single page App could be good enough for your proof of concept, prototype or demo and could form a basis on which to evolve.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you have a requirement, fill in the ")
                com.varabyte.kobweb.silk.components.navigation.Link("/contact") {
                    org.jetbrains.compose.web.dom.Text("contact form")
                }
                org.jetbrains.compose.web.dom.Text(" and we can start a discussion.")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/") {
                    org.jetbrains.compose.web.dom.Text("Home")
                }
            }
        }
    }
}
