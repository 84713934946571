package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun OpensourcePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("opensource.md", mapOf())) {
        uk.co.comsci.web.components.layouts.MarkdownLayout("100PcVatFreeBridge fundraising for Open Source Software","How the 100PcVatFreeBridge MTD VAT Bridging software raised funds to help with some of our favourite Opensource software projects") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("open-source-software") }) {
                org.jetbrains.compose.web.dom.Text("Open Source Software")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The second tranche of fundraising was to pay something back to the Open Source Community for some of our favourite projects: ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.justgiving.com/crowdfunding/100pcvatfreebridge") {
                    org.jetbrains.compose.web.dom.Text("Open Source Crowdfunding Page")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Much of the software available today is built using Open Source Software and the 100PcVatFreeBridge App is no exception. It uses libraries from the Apache Software Foundation to decode Excel files and web services hosted in Apache Tomcat. This free software is maintained and made available by a huge band of largely unnoticed volunteers. The idea of this fund is to pay back something to keep the software updated and to help with day-to-day operating expenses such as infrastructure services, Internet provision and documentation.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("apache-software-foundation") }) {
                org.jetbrains.compose.web.dom.Text("Apache Software Foundation")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The Apache Software Foundation (ASF) is the world’s largest Open Source foundation and provides ${'$'}20B+ worth of software for the public good at 100% no cost. We make extensive use of:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("POI library: (Allows the 100PcVatFreeBridge App to read Excel files)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Tomcat: A pure Java HTTP Web server")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Commons library: A repository of reusable Java Components")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("FTP Server: A multithreaded Java implementation of the File transfer service")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Shiro A Java security framework for authorization and cryptography")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Netbeans: A superb cross platform, multi language development environment")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("4th June 2021: £1000 donation")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("16th April 2022: £2000 donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("thunderbird") }) {
                org.jetbrains.compose.web.dom.Text("Thunderbird")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.thunderbird.net") {
                    org.jetbrains.compose.web.dom.Text("https://www.thunderbird.net")
                }
                org.jetbrains.compose.web.dom.Text(" Our email client of choice")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("4th June 2021: £200 donation")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("16th April 2022: £400 donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("pcl-point-cloud-library") }) {
                org.jetbrains.compose.web.dom.Text("PCL Point Cloud Library")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://pointclouds.org") {
                    org.jetbrains.compose.web.dom.Text("https://pointclouds.org")
                }
                org.jetbrains.compose.web.dom.Text(" A great collection of routines for manipulating 3D point cloud data (e.g. from depth cameras)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("19 Jul 2021: £300 donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("jfreechart") }) {
                org.jetbrains.compose.web.dom.Text("JFreeChart")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("http://www.jfree.org") {
                    org.jetbrains.compose.web.dom.Text("http://www.jfree.org")
                }
                org.jetbrains.compose.web.dom.Text(" A free Java chart library.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("4th June 2021: 239.76 Donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("irfanview") }) {
                org.jetbrains.compose.web.dom.Text("Irfanview")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.irfanview.com") {
                    org.jetbrains.compose.web.dom.Text("https://www.irfanview.com")
                }
                org.jetbrains.compose.web.dom.Text(" An excellent image viewer / editor")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("4th June 2021: €200.00 Donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("wikipedia") }) {
                org.jetbrains.compose.web.dom.Text("Wikipedia")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.wikipedia.org") {
                    org.jetbrains.compose.web.dom.Text("https://www.wikipedia.org")
                }
                org.jetbrains.compose.web.dom.Text(" Peer edited resource of mostly unbiased information")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("4th June 2021: £200 donation")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("16th April 2022: £400 donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("paint-net") }) {
                org.jetbrains.compose.web.dom.Text("Paint.net")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.getpaint.net/index.html") {
                    org.jetbrains.compose.web.dom.Text("https://www.getpaint.net/index.html")
                }
                org.jetbrains.compose.web.dom.Text(" Free image editing software.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("16th April 2022: £400 donation")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("vlc-media-player") }) {
                org.jetbrains.compose.web.dom.Text("VLC Media Player")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.videolan.org/vlc") {
                    org.jetbrains.compose.web.dom.Text("https://www.videolan.org/vlc")
                }
                org.jetbrains.compose.web.dom.Text(" A cross-platform multimedia player")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("16th April 2022: €465.00 donation")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("More donations to follow...")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/100PcVatFreeBridge#Fundraising") {
                    org.jetbrains.compose.web.dom.Text("Back")
                }
            }
        }
    }
}
