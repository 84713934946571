package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun MalawiPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("malawi.md", mapOf())) {
        uk.co.comsci.web.components.layouts.MarkdownLayout("100PcVatFreeBridge fundraising for Malawi","How the 100PcVatFreeBridge MTD VAT Bridging software raised funds for the 1st Poulner and Bongolo scouts in Malawi") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("1st-poulner-scout-links-with-malawi") }) {
                org.jetbrains.compose.web.dom.Text("1st Poulner Scout links with Malawi")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("A special thank-you to all of those who donated. Fundraising for the 1st Poulner Scout Adventure to Malawi has been brilliant, £8447.22 raised for this good cause from users of the App. Owing to the Corona virus pandemic, the trip was postponed and then cancelled 😞 However, the funds were used to provide direct support to the Bongolo Scout troops in Malawi and to provide other life-changing experiences for the explorer scouts here.")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("In 2021 the Bongolo Scout troop made a start on the first of several projects. This one is for building toilets, making the scout kitchen ready to set up a Pre school, building bridges across the swampy stream and making shelves and lockable storage on the Bongolo Scout Campsite. Already huge thank you s coming from those scout groups. In 2022, we funded further projects to help with building a Nursery School, purchasing a sewing machine, finishing the bridge building project and training more scout leaders for the 5 Bongolo Scout troops.")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th(attrs = { style {property("text-align", "center")} })
                        org.jetbrains.compose.web.dom.Th(attrs = { style {property("text-align", "center")} })
                        org.jetbrains.compose.web.dom.Th(attrs = { style {property("text-align", "center")} })
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 1\" src=\"images/malawi/Malawi_1.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 2\" src=\"images/malawi/Malawi_2.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 3\" src=\"images/malawi/Malawi_3.jpeg\"")
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 4\" src=\"images/malawi/Malawi_4.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 5\" src=\"images/malawi/Malawi_5.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 6\" src=\"images/malawi/Malawi_6.jpeg\"")
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 7\" src=\"images/malawi/Malawi_7.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 8\" src=\"images/malawi/Malawi_8.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 9\" src=\"images/malawi/Malawi_9.jpeg\"")
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 10\" src=\"images/malawi/Malawi_10.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 11\" src=\"images/malawi/Malawi_11.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 12\" src=\"images/malawi/Malawi_12.jpeg\"")
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 13\" src=\"images/malawi/Malawi_13.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 14\" src=\"images/malawi/Malawi_14.jpeg\" ")
                        }
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} }) {
                            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Malawi Pic 15\" src=\"images/malawi/Malawi_15.jpeg\"")
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} })
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} })
                        org.jetbrains.compose.web.dom.Td(attrs = { style {property("text-align", "center")} })
                    }
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/100PcVatFreeBridge#Fundraising") {
                    org.jetbrains.compose.web.dom.Text("Back")
                }
            }
        }
    }
}
