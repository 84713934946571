package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/100PcVatFreeBridge")
@Composable
fun PcVatFreeBridgePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("PcVatFreeBridge.md", mapOf())) {
        uk.co.comsci.web.components.layouts.MarkdownLayout("Best Free MTD VAT Bridging Software for Excel","HMRC recognised Making tax digital bridging software for VAT that integrates easily with Excel or CSV data") {
            com.varabyte.kobweb.compose.dom.GenericTag("script", "type=\"text/javascript\" src=\"//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js\" async=\"\"")
            org.jetbrains.compose.web.dom.H1(attrs = { id("free-making-tax-digital-vat-software") }) {
                org.jetbrains.compose.web.dom.Text("Free Making Tax Digital VAT Software")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/mtd/vat_mtd_Bridge_software.jpg\" alt=\"MTD VAT spreadsheet\"")
            org.jetbrains.compose.web.dom.H2(attrs = { id("hmrc-recognised-mtd-vat-bridging-software-for-excel") }) {
                org.jetbrains.compose.web.dom.Text("HMRC recognised MTD VAT Bridging Software for Excel")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If your accounting system is based on spreadsheets or can generate CSV output then the 100PcVatFreeBridge App is ideal for you.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("On the 1st November 2022, HMRC withdrew the ability to enter your VAT return box values online via your HMRC login. Almost all companies now have to be signed up to MTD and must have 'digital links' in place for submitting VAT returns. 100PcVatFreeBridge can interpret the data from your spreadsheet or CSV file and present it in the right format for filing the return electronically with HMRC's secure gateway.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("how-do-i-submit-my-mtd-vat-return") }) {
                org.jetbrains.compose.web.dom.Text("How do I submit my MTD VAT Return?")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Watch the Video overview & tutorial: ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100PcVatFreeBridgeGuide/tutorial.html") {
                        org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge Tutorial")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("what-do-i-need-to-file-my-mtd-vat-return") }) {
                org.jetbrains.compose.web.dom.Text("What do I need to file my MTD VAT Return?")
            }
            org.jetbrains.compose.web.dom.Ol {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A Windows PC or Laptop. (Linux or Macs see Further Information below)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A copy of the 100PcVatFreeBridge software. Download ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100PcVatFreeBridge-1.4.10.msi") {
                        org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge Windows Installer")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A spreadsheet (Excel or OpenOffice in .xls .xlsx format) or CSV file with your VAT input data.")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                    org.jetbrains.compose.web.dom.Text(" Download ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/SpreadSheetExample.xls") {
                        org.jetbrains.compose.web.dom.Text("Example 4 Quarters Spreadsheet")
                    }
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                    org.jetbrains.compose.web.dom.Text(" Download ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/SpreadSheetSingle.xls") {
                        org.jetbrains.compose.web.dom.Text("Example Single Return Spreadsheet")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("You will need to 'mark up' the spreadsheet with 'Named Cells' so that the 100PcVatFreeBridge can find the right figures. (User manual download: ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100PcVatFreeBridgeUserManual.pdf") {
                        org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge User Guide")
                    }
                    org.jetbrains.compose.web.dom.Text(")")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Install and Run 100PcVatFreeBridge, load your data, grant authority with HMRC")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Submitting your return is then only a few clicks away. Easy!")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/mtd/100PcVatFreeBridgeScreenshot.jpg\" alt=\"100PcVatFreeBridge screenshot\"")
            org.jetbrains.compose.web.dom.H2(attrs = { id("why-choose-100pcvatfreebridge-for-vat-mtd") }) {
                org.jetbrains.compose.web.dom.Text("Why Choose 100PcVatFreeBridge for VAT MTD?")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("It's a standalone App, not a cloud solution. Your data is yours.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("It's compliant provided that your process incorporates the mandatory digital links.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Your data stays on your PC and only the VAT return figures are sent to HMRC when submitted.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("There is no significant setup or lock-in when using 100PcVatFreeBridge. You can easily swap to something else if it doesn't work for you.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("It has passed HMRC's testing and is an HMRC recognised MTD VAT application.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("No requirement to register or disclose your email address so no spam ever.")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                    org.jetbrains.compose.web.dom.Text(" (Unless you want to provide feedback, good or bad 😄)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("It's compatible with any annual, quarterly or monthly VAT system, cash accounting or flat rate scheme that can generate values for the old school VAT return Boxes 1, 4, 6, and 7 as a minimum.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("It's FREE. Yours to keep. (Apart from an optional donation to any charitable cause)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("How can it be free? Where's the catch? There isn't one! Making the App free generates website exposure for my custom software services. Please spread the word. All backlinks help in the Google search ranking game.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Don't just take our word for it. Check Comsci's 'Excellent' rating on ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://uk.trustpilot.com/review/comsci.co.uk") {
                    org.jetbrains.compose.web.dom.Text("TrustPilot")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/trustpilot1.jpg\" alt=\"Trustpilot rating\" class=\"narrow-port align-centre\"")
            org.jetbrains.compose.web.dom.H2(attrs = { id("use-your-windows-pc-mac-or-linux-workstation-to-file-your-mtd-vat-return") }) {
                org.jetbrains.compose.web.dom.Text("Use your Windows PC, Mac or Linux workstation to file your MTD VAT Return")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It is available for 64 bit Windows as the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100PcVatFreeBridge-1.4.10.msi") {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge Windows Installer Package")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For Mac users (macOS 10.12 or later) there's a signed package : ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100PcVatFreeBridge-1.4.10.pkg") {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge installer PKG")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For Ubuntu 20.04 users as a self-contained deb package: ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/pcvatfreebridge_1.4.10-ubuntu-20.04.deb") {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge 20.04 DEB package")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For Ubuntu 22.04 users as a self-contained deb package: ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/pcvatfreebridge_1.4.10-ubuntu-22.04.deb") {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge 22.04 DEB package")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("can-i-trust-100pcvatfreebridge-to-file-my-mtd-vat-return") }) {
                org.jetbrains.compose.web.dom.Text("Can I trust 100PcVatFreeBridge to file my MTD VAT Return?")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100pcVatFreeBridge-privacy-policy.pdf") {
                    org.jetbrains.compose.web.dom.Text("Privacy Policy")
                }
                org.jetbrains.compose.web.dom.Text("       ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/doc/100pcVatFreeBridge-terms-and-conditions.pdf") {
                    org.jetbrains.compose.web.dom.Text("Terms and Conditions")
                }
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge employs the latest encryption technology to protect your data.")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Your spreadsheets are opened 'read only' so that your data will not be modified. https secure communications are used to communicate with HMRC and update checks.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("No tracking data or cookies are used by the App.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge has been virus scanned and is 'Digitally Signed'. If you right-click on the installer and check that the digital signer is listed as 'Comsci Ltd' you can be sure that the App has not been tampered with.")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            com.varabyte.kobweb.compose.dom.GenericTag("div", "class=\"trustpilot-widget\" data-locale=\"en-GB\" data-template-id=\"5419b6a8b0d04a076446a9ad\" data-businessunit-id=\"624968dff732c01f0371c45e\" data-style-height=\"24px\" data-style-width=\"100%\" data-theme=\"dark\"") {
                com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"https://uk.trustpilot.com/review/comsci.co.uk\" target=\"_blank\" rel=\"noopener\"") {
                    org.jetbrains.compose.web.dom.Text("""Trustpilot""")
                }
            }

            com.varabyte.kobweb.compose.dom.GenericTag("br")
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.compose.dom.GenericTag("span", "id=\"Fundraising\"") {
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("fundraising") }) {
                org.jetbrains.compose.web.dom.Text("Fundraising")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("piece-by-piece-we-can-make-the-world-a-better-place-to-be") }) {
                org.jetbrains.compose.web.dom.Text("\"Piece by piece, we can make the world a better place to be\"")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge is a free app. However, we do encourage you to make a charitable donation:")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Either directly to any worthy cause close to your heart. Please make your donation independently but do let us know either via Email, Facebook or on a TrustPilot review so that we can tally up and share the good work done.")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Or donate here to the 100PcVatFreeBridge project to help with the ongoing development and maintenance costs. This will help keep the App secure from vulnerabilities and compatible with any future HRMC API changes.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Donate with PayPal")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("form", "action=\"https://www.paypal.com/donate\" method=\"post\" target=\"_top\"") {
                com.varabyte.kobweb.compose.dom.GenericTag("input", "type=\"hidden\" name=\"hosted_button_id\" value=\"AA2MKHU9DJ73Y\"")
                com.varabyte.kobweb.compose.dom.GenericTag("input", "type=\"image\" src=\"https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif\" name=\"submit\" title=\"PayPal - The safer, easier way to pay online!\" alt=\"Donate with PayPal button\"")
                com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"\" border=\"0\" src=\"https://www.paypal.com/en_GB/i/scr/pixel.gif\" width=\"1\" height=\"1\"")
            }

            com.varabyte.kobweb.compose.dom.GenericTag("br")
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H2(attrs = { id("previous-fundraising-campaigns") }) {
                org.jetbrains.compose.web.dom.Text("Previous Fundraising Campaigns")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge was originally written to help provide a bridge from our simple Excel based accounts and then shared publicly to help fund raise for the 1st Poulner Scouts Adventure to Malawi. Thanks to donations from generous users an incredible £8447.22 was raised. Although, the trip to Malawai was postponed and later cancelled due to the Corona Virus outbreak, the funds have been used to help the Bongolo Scout troop build much needed infrastructure and to help the 1st Poulner Explorers with other life-changing experiences.")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/malawi") {
                    org.jetbrains.compose.web.dom.Text("1st Poulner partnership with Bongolo Scouts, Malawi")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The second tranche of fundraising was to raise £5000 to support some OpenSource software projects that are crucial to performing our work. Thanks to your generous support we smashed this target and raised a fantastic £8430.")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/opensource") {
                    org.jetbrains.compose.web.dom.Text("OpenSource Software Donations")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("/") {
                    org.jetbrains.compose.web.dom.Text("Back")
                }
            }
        }
    }
}
